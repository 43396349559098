import React, { useState } from "react";
import Notification from "../components/notification";

export type NotificationContextType = {
  showNotification: (body: string, timeout?: number) => void;
  notification: JSX.Element;
};

const NotificationContext = React.createContext<NotificationContextType>(null!);

export const useNotification = () => React.useContext(NotificationContext);

export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [notif, setNotif] = useState<{
    body: string;
    show: boolean;
  }>({
    body: "",
    show: false,
  });
  const [t, setT] = useState<NodeJS.Timeout | null>(null);
  const showNotification = (body: string, timeout?: number): void => {
    setNotif({
      body,
      show: true,
    });
    if (timeout && timeout > 0) {
      setT(
        setTimeout(() => {
          setNotif({
            show: false,
            body: "",
          });
        }, timeout)
      );
    }
  };

  const notification = (
    <Notification
      body={notif.body}
      dismiss={() => {
        if (t) {
          clearTimeout(t);
          setT(null);
        }
        setNotif({
          body: "",
          show: false,
        });
      }}
      show={notif.show}
    />
  );

  const value = {
    showNotification,
    notification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
