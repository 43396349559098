/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { Editor } from "@tiptap/react";
import { usePopper } from "react-popper";

import AddIcon from "../../icons/Add";
import ImageIcon from "../../icons/Image";
import PlayIcon from "../../icons/Play";
import DocsIcon from "../../icons/Docs";
import TableIcon from "../../icons/Table";
import FunctionIcon from "../../icons/Function";
import CodeIcon from "../../icons/Code";
import QuoteIcon from "../../icons/Quote";
import ImageModal from "./ImageModal";
import YoutubeModal from "./YoutubeModal";
import HorizontalRuleIcon from "../../icons/HorizontalRule";

const FloatingMenuButton = ({
  icon,
  onClick,
}: {
  icon: any;
  onClick?: () => void;
}) => {
  return (
    <button
      type="button"
      className="h-6 w-6 rounded-full border flex items-center justify-center border-gray-light border-solid"
      disabled={!onClick}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
    >
      {icon}
    </button>
  );
};

export default function FloatingMenuPopover({
  editor,
  show,
  setShow,
}: {
  editor: Editor;
  show: boolean;
  setShow: (show: boolean | ((prev: boolean) => boolean)) => void;
}) {
  const [showImageModal, setShowImageModal] = useState(false);
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
    placement: "right-end",
  });

  return (
    <div className="flex items-center">
      <button
        type="button"
        ref={setReferenceElement}
        onClick={() => {
          setShow((show) => !show);
        }}
      >
        <AddIcon className="text-gray-light" active={show} />
      </button>

      {show && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="flex flex-row items-center gap-1">
            <FloatingMenuButton
              icon={<ImageIcon />}
              onClick={() => setShowImageModal(true)}
            />
            <FloatingMenuButton
              icon={<PlayIcon />}
              onClick={() => {
                setShowYoutubeModal(true);
              }}
            />
            <FloatingMenuButton icon={<DocsIcon />} />
            <FloatingMenuButton icon={<TableIcon />} />
            <FloatingMenuButton icon={<FunctionIcon />} />
            <FloatingMenuButton
              icon={<CodeIcon />}
              onClick={() => {
                editor.chain().focus().setCodeBlock().run();
              }}
            />
            <FloatingMenuButton
              icon={<QuoteIcon />}
              onClick={() => {
                editor.chain().focus().setBlockquote().run();
              }}
            />
            <FloatingMenuButton
              icon={<HorizontalRuleIcon />}
              onClick={() => {
                editor.chain().focus().setHorizontalRule().run();
              }}
            />
          </div>
        </div>
      )}
      <YoutubeModal
        editor={editor}
        open={showYoutubeModal}
        setOpen={setShowYoutubeModal}
      />
      <ImageModal
        editor={editor}
        open={showImageModal}
        setOpen={setShowImageModal}
      />
    </div>
  );
}
