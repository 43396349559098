const CloudIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.61 3.82395C11.202 1.75395 9.384 0.199951 7.2 0.199951C5.466 0.199951 3.96 1.18395 3.21 2.62395C1.404 2.81595 0 4.34595 0 6.19995C0 8.18595 1.614 9.79995 3.6 9.79995H11.4C13.056 9.79995 14.4 8.45595 14.4 6.79995C14.4 5.21595 13.17 3.93195 11.61 3.82395ZM11.4 8.59995H3.6C2.274 8.59995 1.2 7.52595 1.2 6.19995C1.2 4.87395 2.274 3.79995 3.6 3.79995H4.026C4.422 2.41395 5.688 1.39995 7.2 1.39995C9.024 1.39995 10.5 2.87595 10.5 4.69995V4.99995H11.4C12.396 4.99995 13.2 5.80395 13.2 6.79995C13.2 7.79595 12.396 8.59995 11.4 8.59995Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloudIcon;
