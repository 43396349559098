import { useEffect } from "react";

const AddIcon = ({
  active,
  className,
}: {
  active?: boolean;
  className?: string;
}) => {
  useEffect(() => {}, [active]);
  return (
    <div
      className={active ? "transform -rotate-45 focus:ring-0" : "focus:ring-0"}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6554 0.0324688C7.91968 0.360662 5.43105 1.56541 3.49984 3.49648C1.69687 5.29932 0.563659 7.50811 0.115356 10.0932C-0.0279953 10.92 -0.0403791 12.945 0.0930282 13.7472C0.330806 15.177 0.708371 16.3322 1.32709 17.5231C2.22825 19.2576 3.50368 20.7092 5.12624 21.847C9.13681 24.6592 14.5642 24.7219 18.6298 22.0031C19.3614 21.5138 19.8623 21.1026 20.4848 20.4802C22.1509 18.8141 23.2942 16.7062 23.7624 14.437C24.1072 12.7662 24.0751 10.7944 23.6765 9.14978C23.3584 7.8378 22.764 6.48521 22.0071 5.35129C21.5057 4.60011 21.1033 4.11276 20.4388 3.45164C18.9316 1.95224 17.059 0.885339 14.997 0.351187C13.9695 0.0850491 13.4342 0.0187726 12.1799 0.00240279C11.5478 -0.00580555 10.8618 0.00770303 10.6554 0.0324688ZM13.0513 1.25007C17.3066 1.66415 20.9584 4.60874 22.2613 8.67674C22.6351 9.84364 22.7551 10.6484 22.7551 11.9883C22.7551 13.3282 22.6351 14.133 22.2613 15.2999C21.2097 18.5832 18.5871 21.2052 15.3028 22.257C13.2168 22.9251 10.8915 22.9413 8.82754 22.3024C6.44268 21.564 4.4236 20.0669 3.04459 18.0144C0.350602 14.0047 0.637633 8.73519 3.7522 5.02295C6.0084 2.33381 9.52516 0.906962 13.0513 1.25007ZM11.7265 7.25393C11.3809 7.42495 11.3825 7.41482 11.3825 9.52117V11.3786H9.52497C7.41405 11.3786 7.42573 11.3768 7.25663 11.7261C7.13776 11.9716 7.13776 12.0051 7.25663 12.2506C7.42573 12.5999 7.41405 12.5981 9.52497 12.5981H11.3825V14.4555C11.3825 16.5662 11.3807 16.5545 11.73 16.7236C11.9756 16.8425 12.0091 16.8425 12.2546 16.7236C12.6039 16.5545 12.6021 16.5662 12.6021 14.4555V12.5981H14.4596C16.5706 12.5981 16.5589 12.5999 16.728 12.2506C16.8468 12.0051 16.8468 11.9716 16.728 11.7261C16.5589 11.3768 16.5706 11.3786 14.4596 11.3786H12.6021V9.52117C12.6021 7.41041 12.6039 7.42209 12.2546 7.253C12.0085 7.1339 11.9689 7.13395 11.7265 7.25393Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default AddIcon;
