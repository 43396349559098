const LogoIcon = ({
  showGradient,
  className,
}: {
  showGradient?: boolean;
  className?: string;
}) => {
  console.log(className);
  return (
    <svg
      width="29"
      height="46"
      viewBox="0 0 29 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.43758 1.62861L6.86199 0L5.28622 1.62861L6.86199 3.25723L8.43758 1.62861ZM29 22.9002L25.3169 19.0903L21.9562 22.5655H18.2269L15.4302 19.6723L12.6333 22.5655H6.67297L5.6139 21.4698L4.03812 23.0998L5.6139 24.7287L6.79417 23.5078H12.6333L15.4302 26.4036L18.2252 23.5078H22.22L25.3169 26.7101L29 22.9002ZM9.56082 38.6321H2.69608L1.57582 37.4733L0 39.1031L1.57582 40.7332L2.69608 39.5755H9.66952L11.2754 41.2352L13.3907 39.0455L11.2754 36.8571L9.56082 38.6321ZM13.6937 33.3074H7.84102L6.18348 31.5888L4.0665 33.7785L6.18348 35.9669L7.84102 34.2482H13.7699L15.8992 36.4522L18.5232 33.7393L15.8992 31.0249L13.6937 33.3074ZM17.5673 27.9981H12.9746L10.8075 25.7578L8.1835 28.4693L10.8075 31.1851L12.9735 28.9432H17.7646L20.4607 31.7323L23.7141 28.3681L20.4607 25.0041L17.5673 27.9981ZM17.8244 17.1945H13.0332L10.8673 14.9514L8.24464 17.6656L10.8673 20.3785L13.0332 18.138H17.8911L20.6536 20.9959L23.907 17.6319L20.6536 14.2691L17.8244 17.1945ZM13.8313 11.8883H7.90333L6.24327 10.1697L4.12647 12.3593L6.24327 14.5475L7.90333 12.8306H14.0175L16.0907 14.975L18.7161 12.2624L16.0907 9.54777L13.8313 11.8883ZM9.80801 6.48056H2.67713L1.63723 5.40459L0.0628108 7.03321L1.63723 8.66327L2.83222 7.42426H9.80801L11.4683 9.14291L13.5837 6.95446L11.4683 4.76481L9.80801 6.48056ZM6.66882 42.7428L5.09462 44.3714L6.66882 46L8.24464 44.3714L6.66882 42.7428Z"
        fill={showGradient ? "url(#paint0_linear_717_53468)" : "currentColor"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_717_53468"
          x1="1.88879"
          y1="48.7356"
          x2="26.7604"
          y2="-14.7808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00A2E9" />
          <stop offset="0.54902" stop-color="#7F51F4" />
          <stop offset="1" stop-color="#FF00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoIcon;
