const PlayIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.17859 0.0265947C0.655077 0.13608 0.208488 0.505016 0.0567405 0.953423C0.00218568 1.1146 0 1.2704 0 5.00214C0 8.85834 0.000389336 8.88431 0.0622201 9.05446C0.236634 9.53447 0.716339 9.9036 1.26913 9.98307C1.48954 10.0148 1.65299 10.0033 1.91631 9.93747C2.02485 9.91035 3.32629 9.25162 5.73394 8.00517C9.03685 6.29526 9.40448 6.09812 9.57018 5.94805C9.86334 5.68251 10 5.38071 10 4.9988C10 4.6169 9.86334 4.3151 9.57018 4.04956C9.40445 3.89946 9.03712 3.70252 5.73394 1.9926C3.51109 0.841954 2.01676 0.0844768 1.9169 0.0577662C1.6791 -0.0058434 1.39172 -0.0179596 1.17859 0.0265947ZM1.81151 0.661507C2.01431 0.740372 9.03436 4.40197 9.11371 4.47029C9.42244 4.73608 9.41505 5.2821 9.09928 5.53596C9.0429 5.58129 7.37784 6.45814 5.39916 7.48448C2.47323 9.00219 1.771 9.35582 1.63778 9.37845C1.45274 9.40993 1.26371 9.3817 1.079 9.29502C0.929318 9.2248 0.737957 9.02193 0.693942 8.88678C0.64334 8.73142 0.64334 1.26618 0.693942 1.11082C0.763678 0.896725 1.03112 0.68439 1.3095 0.622102C1.44115 0.592638 1.68323 0.611638 1.81151 0.661507Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlayIcon;
