import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CloudIcon from "../../icons/Cloud";
import CloudCheckIcon from "../../icons/CloudCheck";
import LogoIcon from "../../icons/Logo";
import { classNames } from "../../utils";

const DisappearingSaved = ({ className }: { className?: string }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <span
      className={classNames(
        className,
        "transition-opacity duration-700 ease-in",
        show ? "opacity-100" : "opacity-0"
      )}
    >
      Saved!
    </span>
  );
};

export type savedType = "default" | "saving" | "saved" | "failure";

const TopNav = ({
  slug,
  type = "blog",
  status,
}: {
  slug?: string;
  type?: string;
  status: savedType;
}) => {
  return (
    <div className="sticky top-0 z-50 flex bg-background-global border-b border-gray-border px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <Link to={"/"}>
        <LogoIcon
          className="flex-initial text-brand-primary hover:text-brand-dark h-12 w-12"
          showGradient={true}
        />
      </Link>
      <div className="flex flex-col flex-1 ml-5 min-w-0">
        <div className="flex flex1 text-font-placeholder">
          <div className="flex1">
            example.com/{type}/<span className="text-font-normal">{slug}</span>
          </div>
          <div className="flex items-end flex-1 mb-px ml-2">
            <>
              {status === "default" && (
                <CloudCheckIcon className="h-5 w-5 text-brand-primary" />
              )}
              {status === "saved" && (
                <>
                  <CloudCheckIcon className="h-5 w-5 text-brand-primary" />
                  <DisappearingSaved className="text-brand-primary ml-1" />
                </>
              )}
              {status === "saving" && (
                <>
                  <CloudIcon className="h-5 w-5" />
                  <span className="ml-1">Saving...</span>
                </>
              )}
              {status === "failure" && (
                <>
                  <CloudIcon className="h-5 w-5 text-semantic-negative" />
                  <span className="ml-1">Error</span>
                </>
              )}
            </>
          </div>
        </div>
        {/* <div className="text-4xl font-medium text-gray-900 sm:truncate">
          Post Editor
        </div> */}
      </div>
      <div className="flex-initial">
        <button
          type="button"
          className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-primary hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:order-1 sm:ml-3"
        >
          Review
        </button>
      </div>
    </div>
  );
};

export default TopNav;
