import { useEffect, useState } from "react";
import PostList from "../components/postList";
import type { Post } from "../../../types";
import { useAuth } from "../hooks/auth";

export type PostsResponse = {
  posts: Post[];
  metadata: {
    count: number;
  };
};

export default function Posts() {
  const { axios } = useAuth();
  const [posts, setPosts] = useState<Post[]>([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get<PostsResponse>("/api/posts");
      const postData = res.data.posts;

      // sort by published
      postData.sort((a, b) => {
        // all drafts first
        if (a.status === "draft" && b.status !== "draft") {
          return -1;
        }
        if (b.status === "draft" && a.status !== "draft") {
          return 1;
        }
        if (a.status === "draft" && b.status === "draft") {
          return (
            new Date(b.createdAt ?? 0).getTime() -
            new Date(a.createdAt ?? 0).getTime()
          );
        }

        return (
          new Date(b.publishedAt ?? 0).getTime() -
          new Date(a.publishedAt ?? 0).getTime()
        );
      });

      setPosts(res.data.posts);
    })();
  }, [axios]);

  return (
    <main style={{ padding: "1rem 0" }}>
      {/* <PostTable posts={posts} /> */}
      <PostList posts={posts} />
    </main>
  );
}
