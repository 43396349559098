const OrderedListIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21528 0.0540703C2.11647 0.118805 2.02155 0.283102 2.0041 0.419602C1.97514 0.646266 2.11253 0.923695 2.28257 0.981914C2.38853 1.01817 9.61169 1.01817 9.71766 0.981914C9.88769 0.923695 10.0251 0.646266 9.99613 0.419602C9.97867 0.283102 9.88376 0.118805 9.78495 0.0540703L9.70239 0H2.29783L2.21528 0.0540703ZM2.32002 4.00615C2.12662 4.06556 2.0049 4.25639 2.00501 4.5C2.00511 4.70306 2.08283 4.85808 2.23575 4.96015C2.2991 5.00245 2.42503 5.00391 6.00011 5.00391H9.69893L9.77384 4.95344C9.91876 4.85585 9.99527 4.69917 9.99527 4.5C9.99527 4.30083 9.91876 4.14415 9.77384 4.04655L9.69893 3.99609L6.03757 3.99248C4.02382 3.99049 2.35092 3.99666 2.32002 4.00615ZM2.24406 8.03639C2.08481 8.12691 1.9773 8.37066 2.0041 8.5804C2.02155 8.7169 2.11647 8.8812 2.21528 8.94593L2.29783 9H6.10658L6.18914 8.94593C6.28978 8.88 6.38408 8.71434 6.40086 8.57398C6.41854 8.42599 6.37258 8.25638 6.28628 8.15112C6.14737 7.98173 6.28444 7.99214 4.19812 7.9927C2.41411 7.9932 2.31622 7.99537 2.24406 8.03639Z"
        fill="currentColor"
      />
      <path d="M1 0.5C1 0.776142 0.776142 1 0.5 1C0.223858 1 0 0.776142 0 0.5C0 0.223858 0.223858 0 0.5 0C0.776142 0 1 0.223858 1 0.5Z" />
      <path d="M1 4.5C1 4.77614 0.776142 5 0.5 5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4C0.776142 4 1 4.22386 1 4.5Z" />
      <path d="M1 8.5C1 8.77614 0.776142 9 0.5 9C0.223858 9 0 8.77614 0 8.5C0 8.22386 0.223858 8 0.5 8C0.776142 8 1 8.22386 1 8.5Z" />
    </svg>
  );
};

export default OrderedListIcon;
