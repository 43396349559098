import { useEffect, useState } from "react";
import { Post } from "../../../types";
import MonthCalendar from "../components/monthCalendar";
import { useAuth } from "../hooks/auth";
import { PostsResponse } from "./Posts";

export default function Calendar() {
  const { axios } = useAuth();
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get<PostsResponse>("/api/posts");
        setPosts(res.data.posts);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [axios]);

  return (
    <main style={{ padding: "1rem 0" }}>
      <MonthCalendar posts={posts} isLoading={isLoading} />
    </main>
  );
}
