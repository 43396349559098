const ItalicIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89716 0.954752C3.80327 0.978614 3.6755 1.06893 3.61995 1.15071C3.44502 1.40831 3.54007 1.76798 3.81856 1.90228C3.89271 1.93805 3.93727 1.9417 4.30389 1.94209C4.52592 1.94232 4.70746 1.94906 4.70731 1.95703C4.70717 1.965 4.16195 3.52167 3.49571 5.41626L2.28436 8.86098L1.67713 8.87066L1.0699 8.88033L0.984341 8.93398C0.833264 9.02871 0.749154 9.18761 0.750006 9.37666C0.75066 9.52297 0.797287 9.63285 0.902407 9.73571C1.05367 9.88369 0.990963 9.87878 2.65023 9.8727L4.11792 9.8673L4.21509 9.8099C4.33843 9.73702 4.41082 9.6438 4.44729 9.51089C4.51483 9.26467 4.40608 9.01923 4.18142 8.91089C4.10727 8.87513 4.06271 8.87147 3.6961 8.87108C3.47406 8.87085 3.29252 8.86413 3.29267 8.85614C3.29281 8.84815 3.83803 7.2915 4.50427 5.39691L5.71563 1.95219L6.31378 1.94252C6.89408 1.93313 6.91449 1.9315 6.9977 1.88779C7.15491 1.80519 7.25086 1.63417 7.24999 1.43812C7.24932 1.29021 7.20313 1.18077 7.0979 1.07779C6.94795 0.931065 7.00753 0.935942 5.38916 0.938167C4.60018 0.939271 3.92876 0.946721 3.89716 0.954752Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ItalicIcon;
