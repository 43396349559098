import { useEffect, useState } from "react";
import type { User } from "../../../types";
import { useAuth } from "../hooks/auth";
import UserList from "../components/userList";

type UsersResponse = {
  users: User[];
  metadata: {
    count: number;
  };
};

export default function Users() {
  const { axios } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get<UsersResponse>("/api/users");
      setUsers(res.data.users);
    })();
  }, [axios]);
  return (
    <main style={{ padding: "1rem 0" }}>
      <h1>Users</h1>
      <UserList users={users} />
    </main>
  );
}
