import { Link } from "react-router-dom";

type TitleProps = {
  name: string;
};
export default function Title({ name }: TitleProps) {
  return (
    <div className="bg-background-component px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div className="flex-1 min-w-0">
        <h1 className="text-4xl font-medium text-gray-900 sm:truncate">
          {name}
        </h1>
      </div>
      <div className="mt-4 flex sm:mt-0 sm:ml-4">
        <Link to="/post">
          <button
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-primary hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:order-1 sm:ml-3"
          >
            New Post
          </button>
        </Link>
      </div>
    </div>
  );
}
