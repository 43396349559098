const HorizontalRuleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="15"
      height="2"
      viewBox="0 0 15 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.4707"
        y1="1.47058"
        x2="6.4707"
        y2="1.47058"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="9.4707"
        y1="1.47058"
        x2="14.4707"
        y2="1.47058"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HorizontalRuleIcon;
