const HeaderIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="6"
      height="7"
      viewBox="0 0 6 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.446043 0.0167494C0.247806 0.0567781 0.148067 0.131674 0.0631636 0.304227L0 0.432598V3.48901C0 6.35511 0.00211385 6.55149 0.0340932 6.64305C0.0798417 6.77408 0.167208 6.8755 0.288008 6.9378C0.370274 6.98024 0.420696 6.99075 0.57429 6.99751C0.829757 7.00872 0.951818 6.97257 1.07514 6.84919C1.24731 6.67693 1.23992 6.74507 1.24644 5.26965L1.25216 3.97449H3.00608H4.75998L4.76535 5.28906C4.77063 6.58061 4.77144 6.60517 4.81172 6.69229C4.86808 6.81417 4.97804 6.91506 5.10776 6.96392C5.19865 6.99815 5.24906 7.00383 5.41095 6.99801C5.64997 6.98943 5.79009 6.93146 5.88568 6.80155C6.00966 6.63306 6.00316 6.82647 5.99758 3.46908L5.9925 0.41401L5.94642 0.312007C5.89386 0.195685 5.80143 0.106314 5.6735 0.0481049C5.54081 -0.012239 5.21799 -0.00936714 5.07632 0.0534409C4.94854 0.110079 4.85505 0.212586 4.80216 0.353996C4.76184 0.461819 4.76103 0.48811 4.76103 1.69536V2.92672H3.00674H1.25247L1.24681 1.67962L1.24116 0.43254L1.17699 0.302151C1.12985 0.206338 1.0904 0.156336 1.0283 0.113571C0.882177 0.0130047 0.653221 -0.0250838 0.446043 0.0167494Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HeaderIcon;
