const CodeIcon = ({ className }: { className?: string }) => {
  return (
    <div>
      <svg
        width="11"
        height="9"
        viewBox="0 0 11 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.96467 0.0292267C5.86128 0.0670712 5.77328 0.15205 5.72792 0.257794C5.7074 0.305635 5.44208 2.02834 5.0816 4.45438C4.42544 8.87012 4.45157 8.65274 4.55779 8.81407C4.6128 8.89759 4.78816 9.0002 4.87556 9C5.04213 8.99963 5.20574 8.89271 5.27048 8.74193C5.29257 8.69049 5.54267 7.06845 5.91798 4.54265C6.40483 1.26629 6.52571 0.410099 6.51082 0.343325C6.48227 0.215139 6.40268 0.112286 6.28516 0.0516907C6.16596 -0.0097652 6.08638 -0.0153481 5.96467 0.0292267ZM2.78159 1.34273C2.67735 1.39573 0.123422 4.16264 0.0479227 4.30437C-0.0159742 4.42435 -0.0159742 4.5727 0.0479227 4.69259C0.104107 4.79798 2.65835 7.58411 2.74947 7.63939C2.7831 7.65978 2.8537 7.68255 2.90638 7.68996C3.22391 7.73465 3.46761 7.39191 3.33198 7.09136C3.30708 7.03621 2.85958 6.53316 2.13942 5.7508L0.986718 4.49852L2.13942 3.24623C2.85958 2.46387 3.30708 1.96082 3.33198 1.90567C3.49254 1.54993 3.12116 1.17008 2.78159 1.34273ZM7.89872 1.33103C7.67456 1.4131 7.56654 1.68174 7.6676 1.90567C7.6925 1.96082 8.14002 2.4639 8.86022 3.24629L10.013 4.49867L8.86031 5.75087C8.14204 6.53117 7.69259 7.03635 7.66771 7.09136C7.53182 7.39169 7.77563 7.73467 8.0932 7.68996C8.14589 7.68255 8.21674 7.65962 8.25067 7.63903C8.34224 7.58349 10.8953 4.79849 10.9517 4.69266C10.9823 4.63507 11 4.56414 11 4.49852C11 4.43289 10.9823 4.36197 10.9517 4.30437C10.8744 4.15939 8.32108 1.39445 8.21625 1.34231C8.11497 1.29191 8.01523 1.28836 7.89872 1.33103Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default CodeIcon;
