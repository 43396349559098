import { useCallback, useEffect, useMemo, useRef } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import { Extension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
// import Collaboration from "@tiptap/extension-collaboration";
import { Collaboration } from "./collaborationExtension";
import { Link } from "@tiptap/extension-link";
import { Image } from "@tiptap/extension-image";
import { Youtube } from "@tiptap/extension-youtube";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Heading } from "@tiptap/extension-heading";
import { Text } from "@tiptap/extension-text";
import * as Y from "yjs";
import { WebrtcProvider } from "y-webrtc";
import * as awarenessProtocol from "y-protocols/awareness.js";
import debounce from "lodash/debounce";

import { DOMSerializer, Node, Schema } from "prosemirror-model";
import { createHTMLDocument, VHTMLDocument } from "zeed-dom";

import EditorMenuBar from "./EditorButtons";

import "./editor.scss";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { Base64 } from "js-base64";
import { yUndoPlugin, yXmlFragmentToProsemirrorJSON } from "y-prosemirror";
import Post from "../../../../types/Post";

const getHTMLFromFragment = (doc: Node, schema: Schema): string => {
  const document = DOMSerializer.fromSchema(schema).serializeFragment(
    doc.content,
    {
      document: createHTMLDocument() as unknown as Document,
    }
  ) as unknown as VHTMLDocument;

  return document.render();
};

const Editor = ({
  id,
  userName,
  content,
  save,
}: {
  id?: string;
  userName: string;
  content?: string;
  save: ({ title, content }: Partial<Post>) => void;
}) => {
  const { provider, ydoc } = useMemo(() => {
    const ydoc = new Y.Doc();
    if (content) {
      console.log(content);
      const update = Base64.toUint8Array(content);
      Y.applyUpdateV2(ydoc, update);
    }
    if (id) {
      console.log(`creating webrtc provider with id ${id}`);
      const provider = new WebrtcProvider(id, ydoc, {
        signaling: ["wss://signaling.yjs.dev"], // TODO use our own server
        password: null,
        awareness: new awarenessProtocol.Awareness(ydoc),
        maxConns: 20,
        filterBcConns: true,
        peerOpts: {},
      });
      return { ydoc, provider };
    }
    console.log("creating local provider");
    const provider = new WebrtcProvider("local", ydoc);
    return { ydoc, provider };
  }, [id, content]);

  useEffect(() => {
    console.log(`You will be known as user ${userName}`);
  }, [userName]);

  const titleEditor = useEditor({
    onUpdate: ({ editor }) => {
      if (!(editor.view.state as any)["y-undo$"]) {
        return;
      }
      const hasChanged =
        (editor.view.state as any)["y-undo$"]["undoManager"].undoStack.length >
        0;
      if (!hasChanged) return;
      debouncedSave();
    },
    extensions: [
      Document.extend({
        content: "heading",
      }),
      Text,
      Heading.configure({
        levels: [1],
      }),
      Extension.create({
        addKeyboardShortcuts() {
          return {
            "Mod-s": () => {
              localSave();
              return true;
            },
          };
        },
      }),
      Collaboration.configure({
        document: ydoc,
        field: "title",
      }),
      CollaborationCursor.configure({
        provider,
        user: {
          name: userName,
          color: "#E6DDFF",
        },
      }),
    ],
  });

  const titleSchema = useRef(titleEditor?.schema);
  titleSchema.current = titleEditor?.schema;

  const bodyEditor = useEditor(
    {
      onCreate: ({ editor }) => {
        setTimeout(() => {
          // this bullshit is because the yUndoPlugin registration must occur after
          // the BubbleMenu gets instantiated in the EditorMenuBar. See
          // https://github.com/ueberdosis/tiptap/issues/2827
          const yUndo = yUndoPlugin();
          editor.registerPlugin(yUndo);
          console.log(editor);
          console.log({ yUndo });
          const hasChanged =
            (editor.view.state as any)["y-undo$"]["undoManager"].undoStack
              .length > 0;
          console.log({ hasChanged });
        }, 400);
      },
      onUpdate: ({ editor }) => {
        // console.log(props);
        // console.log("body editor update");
        // console.log(props.editor.extensionManager.plugins);
        if (!(editor.view.state as any)["y-undo$"]) {
          return;
        }
        const hasChanged =
          (editor.view.state as any)["y-undo$"]["undoManager"].undoStack
            .length > 0;
        if (!hasChanged) return;
        debouncedSave();
      },
      extensions: [
        StarterKit.configure({
          history: false,
          heading: false,
        }),
        Heading.configure({
          levels: [2, 3],
        }),
        Link.configure({
          openOnClick: false,
        }),
        Image,
        Youtube,
        Extension.create({
          addKeyboardShortcuts() {
            return {
              "Mod-s": () => {
                localSave();
                return true;
              },
            };
          },
        }),
        Collaboration.configure({
          document: ydoc,
          field: "body",
        }),
        CollaborationCursor.configure({
          provider,
          user: {
            name: userName,
            color: "#E6DDFF",
          },
        }),
      ],
    },
    [id, ydoc]
  );

  const bodySchema = useRef(bodyEditor?.schema);
  bodySchema.current = bodyEditor?.schema;

  const localSave = useCallback(async () => {
    console.log("--------- calling local save ---------");
    const ydocUpdate = Y.encodeStateAsUpdateV2(ydoc);
    const content = Base64.fromUint8Array(ydocUpdate);

    console.log({
      bodySchema: bodySchema.current,
      titleSchema: titleSchema.current,
    });

    // get body html
    const nodeJson = yXmlFragmentToProsemirrorJSON(ydoc.getXmlFragment("body"));
    const node = Node.fromJSON(bodySchema.current!, nodeJson);
    const bodyHtml = getHTMLFromFragment(node, bodySchema.current!);

    // get title
    const titleJson = yXmlFragmentToProsemirrorJSON(
      ydoc.getXmlFragment("title")
    );
    const titleNode = Node.fromJSON(titleSchema.current!, titleJson);
    const titleHtml = getHTMLFromFragment(titleNode, titleSchema.current!);
    // can do this because only ever a single node here
    const title = titleJson.content[0].content[0]?.text;

    if (title) {
      console.log({ bodyHtml, titleHtml, titleText: title });
      await save({ title, content });
    }
  }, [save, bodySchema, titleSchema, ydoc]);

  const debouncedSave = useMemo(() => debounce(localSave, 1000), [localSave]);

  useEffect(() => {
    return () => {
      console.log("destroying resources");
      provider?.destroy();
    };
  }, [provider]);

  return (
    <div className="flex justify-center bg-background-component">
      <EditorMenuBar editor={bodyEditor} />
      <div className="px-2 sm:px-5 md:px-10 lg:px-20 border shadow-lg bg-background-global">
        {/* lg:w-prose to maximize editor when there is no content */}
        <div className="flex-col grow min-h-screen lg:w-prose max-w-prose pt-10 pb-32">
          <EditorContent className="flex-none" editor={titleEditor} />
          <hr className="mt-2 mb-8" />
          <EditorContent
            className="flex-1"
            onClick={() => {
              bodyEditor?.commands.focus();
            }}
            editor={bodyEditor}
          />
        </div>
      </div>
    </div>
  );
};

export default Editor;
