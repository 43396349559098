import { useState } from "react";
import {
  Editor,
  FloatingMenu,
  BubbleMenu,
  isTextSelection,
} from "@tiptap/react";
import BoldIcon from "../../icons/Bold";
import CodeIcon from "../../icons/Code";
import OrderedListIcon from "../../icons/OrderedList";
import ItalicIcon from "../../icons/Italic";
import StrikethroughIcon from "../../icons/Strikethrough";
import UnorderedListIcon from "../../icons/UnorderedList";
import LinkIcon from "../../icons/Link";
import LinkModal from "./LinkModal";
import FloatingMenuPopover from "./FloatingMenuPopover";
import HeaderIcon from "../../icons/Header";

const FloatingMenuSeparator = () => {
  return (
    <div className="inline-flex border-r rounded item-center w-px h-6 mx-2"></div>
  );
};

const FloatingButton = ({
  editor,
  name,
  icon,
  onClick,
  forceInactive,
  isActive,
}: {
  editor: Editor;
  name: string;
  onClick: Function;
  icon?: any;
  forceInactive?: boolean;
  isActive?: () => boolean;
}) => {
  const active =
    "inline-flex items-center px-2.5 py-1.5 border border-transparent rounded text-brand-primary bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary fill-brand";

  const inactive =
    "inline-flex items-center px-2.5 py-1.5 rounded bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand";

  let className = inactive;
  if (!forceInactive) {
    if (!!isActive ? isActive() : editor.isActive(name)) {
      className = active;
    }
  }

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={className}
    >
      {icon ? icon : name}
    </button>
  );
};

export default function EditorMenuBar({ editor }: { editor: Editor | null }) {
  const [showLinkModal, setShowLinkModal] = useState(false);
  // shows/hides the popover after clicking the +
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  if (!editor) {
    return null;
  }

  return (
    <>
      {/* This bubble menu is for when a user has a cursor on a link */}
      <BubbleMenu
        editor={editor}
        shouldShow={() => {
          return editor.isActive("link");
        }}
      >
        <div className="flex bg-white p-1 rounded shadow border-transparent hover:outline-none focus:outline-none">
          <FloatingButton
            name="link"
            editor={editor}
            icon={<LinkIcon />}
            onClick={() => setShowLinkModal(true)}
            forceInactive={true}
          />
        </div>
      </BubbleMenu>

      {/* This bubble menu is for when a user has selected some text */}
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
        }}
        shouldShow={({ view, state, from, to }) => {
          const { doc, selection } = state;
          const { empty } = selection;

          // Sometime check for `empty` is not enough.
          // Doubleclick an empty paragraph returns a node size of 2.
          // So we check also for an empty text size.
          const isEmptyTextBlock =
            !doc.textBetween(from, to).length &&
            isTextSelection(state.selection);

          if (!view.hasFocus() || empty || isEmptyTextBlock) {
            return false;
          }

          return !editor.isActive("heading", { level: 1 });
        }}
        className="focus:outline-none"
      >
        <div className="flex bg-white p-1 rounded shadow border-transparent hover:outline-none focus:outline-none">
          <FloatingButton
            name="h2"
            editor={editor}
            icon={<HeaderIcon className="h-3 w-3" />}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            isActive={() => {
              return editor.isActive("heading", { level: 2 });
            }}
          />
          <FloatingButton
            name="h3"
            editor={editor}
            icon={<HeaderIcon className="h-2 w-2" />}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            isActive={() => {
              return editor.isActive("heading", { level: 3 });
            }}
          />
          <FloatingMenuSeparator />
          <FloatingButton
            name="bold"
            editor={editor}
            icon={<BoldIcon />}
            onClick={() => editor.chain().focus().toggleBold().run()}
          />
          <FloatingButton
            name="italic"
            editor={editor}
            icon={<ItalicIcon />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          />
          <FloatingButton
            name="strike"
            editor={editor}
            icon={<StrikethroughIcon />}
            onClick={() => editor.chain().focus().toggleStrike().run()}
          />
          <FloatingMenuSeparator />
          <FloatingButton
            name="orderedList"
            editor={editor}
            icon={<OrderedListIcon />}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          />
          <FloatingButton
            name="bulletList"
            editor={editor}
            icon={<UnorderedListIcon />}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          />
          <FloatingMenuSeparator />
          <FloatingButton
            name="code"
            editor={editor}
            icon={<CodeIcon />}
            onClick={() => editor.chain().focus().toggleCode().run()}
          />
          <FloatingButton
            name="link"
            editor={editor}
            icon={<LinkIcon />}
            onClick={() => setShowLinkModal(true)}
          />
        </div>
      </BubbleMenu>

      <FloatingMenu
        editor={editor}
        className="outline-none"
        tippyOptions={{
          duration: 100,
          zIndex: 5,
          onShow: () => {
            setShowFloatingMenu(false);
          },
        }}
      >
        <FloatingMenuPopover
          editor={editor}
          show={showFloatingMenu}
          setShow={setShowFloatingMenu}
        ></FloatingMenuPopover>
      </FloatingMenu>

      <LinkModal
        open={showLinkModal}
        setOpen={setShowLinkModal}
        editor={editor}
      ></LinkModal>
    </>
  );
}
