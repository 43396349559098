import { classNames } from "../utils";

const ArrowIcon = ({
  className,
  direction,
}: {
  className?: string;
  direction?: "left" | "right" | "up" | "down";
}) => {
  let transformValue = "";
  switch (direction) {
    case "left": {
      transformValue = "transform rotate-180";
      break;
    }
    case "right": {
      transformValue = "";
      break;
    }
    case "up": {
      transformValue = "transform -rotate-90";
      break;
    }
    case "down": {
      transformValue = "transform rotate-90";
      break;
    }
  }

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, transformValue)}
    >
      <path
        d="M0 6.9899V9.0101H12.1212L6.56566 14.5657L8 16L16 8L8 -7.70646e-08L6.56566 1.43434L12.1212 6.9899H0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowIcon;
