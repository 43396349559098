/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { EyeIcon, EyeOffIcon, InboxIcon } from "@heroicons/react/outline";
import Post, { Status } from "../../../../types/Post";
import ArrowIcon from "../../icons/Arrow";
import { classNames } from "../../utils";
import debounce from "lodash/debounce";
import DropdownWithSupport, {
  Option,
} from "../../components/dropdownWithSupport";

type StatusOption = {
  title: string;
  value: Status;
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  description: string;
};
const statusOptions: StatusOption[] = [
  {
    title: "Private",
    value: "private",
    Icon: EyeOffIcon,
    description: "Only the post owner can see this post.",
  },
  {
    title: "Draft",
    value: "draft",
    Icon: EyeIcon,
    description:
      "This document can be viewed or edited by others in your organization.",
  },
  {
    title: "Published",
    value: "published",
    Icon: EyeIcon,
    description: "This document has been published and is live.",
  },
];

export default function Sidebar({
  post,
  slug,
  setSlug,
  children,
  save,
}: {
  post: Post;
  slug: string;
  setSlug: (slug: string) => void;
  children: JSX.Element;
  save: (update: Partial<Post>) => {};
}) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  console.log("status:", post.status);

  const initialStatus =
    statusOptions.find((s) => s.value === post.status) || statusOptions[0];

  const [status, setStatus] = useState(initialStatus);

  const debouncedSave = useMemo(() => debounce(save, 300), [save]);
  useEffect(() => {
    console.log("new save");
  }, [save]);

  return (
    <>
      <div className="flex">
        <div className="grow">
          <div className="py-6">
            <div className="relative mx-auto max-w-7xl">
              <div
                className={classNames(
                  "absolute cursor-pointer rounded-lg bg-background-global rounded-r-none border border-r-0 py-3 right-0 top-10",
                  sidebarOpen
                    ? "translate translate-x-px"
                    : "px-1.5 rounded-r-lg border-r"
                )}
                onClick={() => {
                  setSidebarOpen((prev) => !prev);
                }}
              >
                <ArrowIcon
                  className={classNames(
                    "text-font-disabled",
                    sidebarOpen ? "translate translate-x-2" : ""
                  )}
                  direction={sidebarOpen ? "right" : "left"}
                />
              </div>
              {children}
            </div>
          </div>
        </div>
        {/* Sidebar Settings */}
        <div
          className={classNames(
            "flex flex-col lg:w-80 bg-background-global border-l p-4",
            sidebarOpen ? "" : "hidden"
          )}
        >
          <div className="text-lg">Page Settings</div>
          <form>
            <DropdownWithSupport
              options={statusOptions}
              selected={status}
              setSelected={(option: Option) => {
                setStatus(option as StatusOption);
                debouncedSave({ status: option.value as Status });
              }}
            />
            <label
              htmlFor="slug"
              className="block mt-2 text-sm font-medium text-gray-700"
            >
              Slug
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="slug"
                id="slug"
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                  debouncedSave({ slug });
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="my-awesome-page-slug"
              />
            </div>
          </form>
          <div className="">bar</div>
          <div className="">foo</div>
          <div className="">bar</div>
          <div className="">foo</div>
          <div className="">bar</div>
        </div>
        {/* <Transition
              show={sidebarOpen}
              as="div"
              className="flex flex-col flex-1 bg-red-500 p-2"
              enter="transition ease-in-out duration-100 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-100 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="">foo</div>
              <div className="">bar</div>
              <div className="">foo</div>
              <div className="">bar</div>
              <div className="">foo</div>
              <div className="">bar</div>
            </Transition> */}
        {/* <div
              className="relative border p-3 mt-2 right-0"
              onClick={() => {
                setSidebarOpen((prev) => !prev);
              }}
            >
              <ArrowIcon />
            </div> */}
      </div>
    </>
  );
}
