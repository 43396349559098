const CloudCheckIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M6 7.99995L3.9 5.89995L4.746 5.04795L6 6.30195L9.108 3.19995L9.954 4.04595L6 7.99995ZM11.61 3.81795C11.202 1.75395 9.384 0.199951 7.2 0.199951C5.466 0.199951 3.96 1.18395 3.21 2.61795C1.404 2.81595 0 4.33995 0 6.19995C0 7.15473 0.379285 8.07041 1.05442 8.74554C1.72955 9.42067 2.64522 9.79995 3.6 9.79995H11.4C11.794 9.79995 12.1841 9.72235 12.548 9.57159C12.912 9.42083 13.2427 9.19985 13.5213 8.92127C13.7999 8.6427 14.0209 8.31198 14.1716 7.948C14.3224 7.58403 14.4 7.19392 14.4 6.79995C14.4 5.21595 13.17 3.93195 11.61 3.81795Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloudCheckIcon;
