import React from "react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { render } from "react-dom";
// import styled from "styled-components";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Posts from "./pages/Posts";
import CalendarIcon from "./icons/Calendar";
import ListIcon from "./icons/List";
import MenuIcon from "./icons/Menu";
import DonutChartIcon from "./icons/DonutChart";
import { AuthProvider, RequireAuth } from "./hooks/auth";
import Users from "./pages/Users";
import Calendar from "./pages/Calendar";
import NotFound from "./pages/NotFound";
import Editor from "./pages/Post";
import User from "./pages/User";
import { NotificationProvider } from "./hooks/notification";

const container = document.getElementById("root");

const navigation = [
  {
    name: "Schedule",
    href: "schedule",
    icon: CalendarIcon,
    element: Calendar,
  },
  { name: "Posts", href: "posts", icon: ListIcon, element: Posts },
  { name: "Assets", href: "assets", icon: MenuIcon, element: Dashboard },
  {
    name: "Analytics",
    href: "analytics",
    icon: DonutChartIcon,
    element: Users,
  },
];

render(
  <BrowserRouter>
    <NotificationProvider>
      <AuthProvider>
        <Routes>
          {/* Post editor does not get put under the main navigation  */}
          <Route
            path="/post"
            element={
              <RequireAuth>
                <Editor />
              </RequireAuth>
            }
          />
          <Route
            path="/post/:id"
            element={
              <RequireAuth>
                <Editor />
              </RequireAuth>
            }
          />
          <Route path="/" element={<App navigation={navigation} />}>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            {navigation.map((n) => {
              return (
                <Route
                  key={`index-${n.name}`}
                  path={n.href}
                  element={
                    <RequireAuth>
                      <n.element />
                    </RequireAuth>
                  }
                />
              );
            })}
            <Route
              path="/user/:id"
              element={
                <RequireAuth>
                  <User />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </AuthProvider>
    </NotificationProvider>
  </BrowserRouter>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
