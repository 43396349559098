import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { User } from "../../../types";
import axios, { AxiosInstance } from "axios";

type UserResponse = {
  errors?: [{ message: string }];
  user: User;
};

type SignInArguments = {
  username: string;
  password: string;
  remember: boolean;
};

type AuthContextType = {
  axios: AxiosInstance;
  loggedIn: boolean;
  user?: User;
  signin: (args: SignInArguments) => Promise<void>;
  signout: () => Promise<void>;
  tryLogin: () => Promise<void>;
};

const AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<User | undefined>();

  // automatically add auth cookie and content-type headers when using this
  // axios instance
  const axiosInstance = axios.create({
    withCredentials: true,
  });
  axiosInstance.interceptors.request.use((config) => {
    config.headers = {
      ContentType: "application/json",
    };
    return config;
  });

  // signin signs in the user with the backend. If there is a problem
  // it throws an error with the message as the relevant information.
  const signin = async (args: SignInArguments): Promise<void> => {
    try {
      const res = await axiosInstance.post<UserResponse>("/api/session", args);
      if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
      } else {
        setUser(res.data.user);
      }
    } catch (e: any) {
      if (e.response?.data?.errors?.length) {
        throw new Error(e.response.data.errors[0].message);
      }
      throw new Error(`Unexpected error: ${e.status}`);
    }
  };

  const signout = async (): Promise<void> => {
    setUser(undefined);
    await axiosInstance.delete("/api/session");
  };

  const tryLogin = async (): Promise<void> => {
    if (user?.id) {
      return;
    }
    const res = await axiosInstance.get<UserResponse>("/api/users/me");
    if (res.data.errors) {
      throw new Error(res.data.errors[0].message);
    }
    setUser(res.data.user);
  };

  const value = {
    user,
    signin,
    signout,
    tryLogin,
    loggedIn: !!user,
    axios: axiosInstance,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let location = useLocation();

  const [loading, setLoading] = React.useState(!auth.loggedIn);

  React.useEffect(() => {
    if (!auth.loggedIn) {
      const loginCheck = async () => {
        try {
          await auth.tryLogin();
        } catch (e) {
          // just forget this ever happened.
        } finally {
          setLoading(false);
        }
      };
      loginCheck();
    }
  }, [auth]);

  if (loading) {
    return <div />;
  }

  if (!auth.loggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
