import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Editor from "./editor";
import type { Post } from "../../../../types";
import { useAuth } from "../../hooks/auth";
import { useNotification } from "../../hooks/notification";
import NotFound from "../NotFound";
import TopNav, { savedType } from "./TopNav";
import Sidebar from "./Sidebar";

type PostResponse = {
  post: Post;
};

type State = {
  loading: boolean;
  post?: Post;
};

const createBlankPost = (): Post => {
  console.log("creating blank post");
  return {
    id: "",
    authors: [],
    content: "",
    updatedAt: "",
    createdAt: "",
    slug: "",
    status: "private",
    title: "",
    type: "blog",
  };
};

export default function PostPage() {
  const { axios, user } = useAuth();
  const [{ loading, post }, setState] = useState<State>({
    loading: true,
    post: undefined,
  });
  console.log("slug:", post?.slug);
  // const [addUserDisabled, setAddUserDisabled] = useState(true);
  // const [slug, setSlug] = useState(post?.slug || "no-slug");

  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { id } = useParams();

  const [status, setStatus] = useState<savedType>("default");

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          const res = await axios.get<PostResponse>(`/api/posts/${id}`);
          setState({
            post: res.data.post,
            loading: false,
          });
        } else {
          setState({
            post: createBlankPost(),
            loading: false,
          });
        }
      } catch (e) {
        setState({
          loading: false,
        });
      }
    })();
  }, [axios, id]);

  const save = useMemo(
    () => async (updates: Partial<Post>) => {
      setStatus("saving");
      console.log({ id });
      try {
        if (id) {
          console.log("updating post");
          await axios.put(`/api/posts/${id}`, updates);
          showNotification("Post Saved!", 1500);
        } else {
          console.log("creating new post");
          const res = await axios.post<PostResponse>(`/api/posts`, {
            ...post,
            authors: [user!], // TODO: allow more authors to be added.
            ...updates,
          });
          const { id } = res.data.post;
          showNotification("Post Created!", 1500);
          navigate(`/post/${id}`, {
            replace: true,
          });
        }
        setStatus("saved");
      } catch (e) {
        showNotification("Something went wrong", 3000);
        console.error(e);
        setStatus("failure");
      }
    },
    [axios, id, navigate, showNotification, user]
  );

  if (loading) return <div></div>;
  if (!post) return <NotFound />;

  // const publish = async (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.preventDefault();
  //   try {
  //     if (post.id) {
  //       await axios.put(`/api/posts/${id}`, {
  //         ...post,
  //         status: "published",
  //         content: editor?.getHTML(),
  //       });
  //       showNotification("Post Saved!", 1500);
  //     } else {
  //       const res = await axios.post<PostResponse>(`/api/posts`, {
  //         ...post,
  //         authors: [user!],
  //         status: "published",
  //         content: editor?.getHTML(),
  //       });
  //       const { id } = res.data.post;
  //       showNotification("Post Saved!", 1500);
  //       navigate(`/post/${id}`, {
  //         replace: true,
  //       });
  //     }
  //   } catch (e) {
  //     showNotification("Something went wrong", 3000);
  //     console.error(e);
  //   }
  // };

  // const authors = post.authors as User[];

  return (
    <div>
      <TopNav slug={post.slug} type={post.type} status={status} />
      <Sidebar
        post={post}
        slug={post.slug}
        setSlug={(slug) => {
          setState((s) => ({
            ...s,
            post: {
              ...post,
              slug,
            },
          }));
        }}
        save={save}
      >
        <Editor
          id={post.id}
          userName={user?.name || "Unknown User"}
          content={post.content}
          save={save}
        />
      </Sidebar>
    </div>
  );
}
