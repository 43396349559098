import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import "./App.scss";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useLocation, Link, Outlet } from "react-router-dom";
import { useAuth } from "./hooks/auth";
import Title from "./components/title";
import { useNotification } from "./hooks/notification";
import UserImage from "./components/userImage";
import LogoIcon from "./icons/Logo";
import { classNames } from "./utils";

export type NavItem = {
  name: string;
  href: string;
  icon: (props: any) => JSX.Element;
};

export type AppProps = {
  navigation: NavItem[];
};

export default function App({ navigation }: AppProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const rootPath = location.pathname.split("/")[1] || "dashboard";
  const title = rootPath.replace(/^\w/, (c) => c.toUpperCase());

  const items = navigation.map((n) => ({
    ...n,
    current: n.name.toLowerCase() === rootPath,
  }));

  const { user } = useAuth();
  const { notification } = useNotification();

  const isDashboard = items.reduce((prev, item) => {
    if (item.current) {
      return false;
    }
    return prev;
  }, true);

  return (
    <>
      <div>
        {/* Dynamic sidebar for mobile */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-background-global">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <Link to={"/"} onClick={() => setSidebarOpen(false)}>
                      <LogoIcon
                        className={classNames(
                          isDashboard
                            ? "text-brand-primary"
                            : "text-font-light hover:text-font-normal"
                        )}
                        showGradient={true}
                      />
                    </Link>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {items.map((item) => (
                      <Link
                        onClick={() => setSidebarOpen(false)}
                        key={item.name}
                        to={`/${item.href}`}
                        className={classNames(
                          item.current
                            ? "bg-brand-primary text-white"
                            : "text-font-light hover:text-font-normal",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-white"
                              : "text-font-light group-hover:text-font-normal",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex bg-background-component border-t border-border p-4">
                  <Link
                    to={`/user/${user?.id}`}
                    className="flex-shrink-0 group block"
                  >
                    <div className="flex items-center h-fit">
                      <div>
                        <UserImage
                          imageUrl={user?.imageUrl}
                          alt={user?.name}
                          className="h-10 w-10"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-font-normal group-hover:text-font-dark">
                          {user?.name}
                        </p>
                        <p className="text-sm font-medium text-font-light group-hover:text-normal">
                          View profile
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden m-3 rounded-lg md:flex md:w-16 md:flex-col md:fixed md:inset-y-0 shadow-md">
          <div className="flex-1 rounded-lg flex flex-col min-h-0 bg-background-global">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center justify-center w-auto flex-shrink-0 h-14">
                <Link to="/">
                  <LogoIcon
                    className={classNames(
                      isDashboard
                        ? "text-brand-primary"
                        : "text-font-light hover:text-font-normal"
                    )}
                    showGradient={true}
                  />
                </Link>
              </div>
              <nav className="mt-5 flex-1 space-y-1">
                {items.map((item) => (
                  <Link
                    key={item.name}
                    to={`/${item.href}`}
                    className={classNames(
                      item.current
                        ? "text-brand-primary border-r-2 border-r-brand-primary"
                        : "text-font-light hover:text-font-normal",
                      "group flex items-center justify-center py-3 text-sm font-medium"
                    )}
                  >
                    <item.icon
                      className="flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                  </Link>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex items-center">
              <Link to={`/user/${user?.id}`} className="flex-shrink-0 w-full">
                <div className="flex justify-center mb-2 items-center">
                  <UserImage
                    className="flex h-12 w-12"
                    imageUrl={user?.imageUrl}
                    alt={user?.name}
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="md:pl-20 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <Title name={title} />
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-background-component">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
      {notification}
    </>
  );
}
