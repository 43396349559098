const BoldIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16181 0.950097C0.770886 1.02127 0.613315 1.52842 0.892641 1.81637C1.01773 1.94533 1.08812 1.96686 1.41117 1.97503L1.70548 1.98245V5.40262V8.82276L1.42114 8.83306C1.10359 8.84456 1.0378 8.86365 0.911936 8.98088C0.651685 9.22327 0.7138 9.64477 1.03599 9.82258L1.11686 9.86721L3.0224 9.87351C4.35003 9.87789 4.98392 9.87275 5.11252 9.85658C5.64214 9.79001 6.11452 9.5599 6.499 9.18125C7.2002 8.49062 7.43239 7.46778 7.10205 6.52462C6.91085 5.97874 6.5001 5.47607 6.02537 5.20706C5.95952 5.16975 5.90196 5.13626 5.89745 5.13266C5.89294 5.12904 5.92498 5.08208 5.96864 5.02827C6.7612 4.05198 6.71733 2.63345 5.8661 1.71315C5.51372 1.33222 5.12318 1.10805 4.60357 0.988563C4.41422 0.945016 4.36801 0.9437 2.83284 0.938352C1.96587 0.935328 1.21391 0.940614 1.16181 0.950097ZM4.48896 2.00804C4.89014 2.12949 5.22967 2.4216 5.39917 2.7912C5.7285 3.50921 5.43736 4.37662 4.75137 4.7212C4.46909 4.86301 4.4575 4.86447 3.54617 4.87362L2.7231 4.88187V3.41858V1.95528L3.54617 1.96353C4.2577 1.97069 4.38547 1.97672 4.48896 2.00804ZM5.18733 5.95488C5.25317 5.97431 5.38337 6.02852 5.47665 6.07533C5.61495 6.14473 5.67978 6.19501 5.82787 6.34768C5.97584 6.50022 6.02479 6.56728 6.09215 6.70975C6.20694 6.95255 6.24258 7.10849 6.24258 7.36798C6.24258 7.62786 6.20678 7.78413 6.09193 8.02564C6.02509 8.16621 5.97401 8.23625 5.82767 8.38791C5.67872 8.54227 5.61591 8.59112 5.47665 8.66083C5.15716 8.8208 5.20812 8.81556 3.89535 8.82348L2.7231 8.83056V7.36798V5.90541L3.89535 5.91248C4.93005 5.91873 5.08166 5.92371 5.18733 5.95488Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BoldIcon;
