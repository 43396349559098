/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { LinkIcon } from "@heroicons/react/outline";
import { Editor } from "@tiptap/react";

export default function LinkModal({
  open,
  setOpen,
  editor,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  editor: Editor;
}) {
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (editor.getAttributes("link").href) {
      // we already have a link
      // first expand selection to entire link
      editor.commands.extendMarkRange("link");
      const { state } = editor.view;
      // get link value
      setUrl(editor.getAttributes("link").href);
      const { to, from } = state.selection;
      const textSelection = state.doc.textBetween(from, to, " ");
      // get link text
      setText(textSelection);
    } else {
      const { state } = editor.view;
      const { to, from } = state.selection;
      const textSelection = state.doc.textBetween(from, to, " ");
      setText(textSelection);
    }
  }, [editor, open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-light sm:mx-0 sm:h-10 sm:w-10">
                    <LinkIcon
                      className="h-6 w-6 text-brand"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Set Link
                    </Dialog.Title>
                  </div>
                </div>
                <form className="mt-5 sm:ml-4 sm:flex sm:items-left flex flex-col">
                  <div className="w-full">
                    <label htmlFor="text">Text</label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      onChange={(e) => setText(e.target.value)}
                      value={text}
                    />
                    <label htmlFor="text">Url</label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      onChange={(e) => setUrl(e.target.value)}
                      value={url}
                    />
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-primary text-base font-medium text-white hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        if (url) {
                          editor
                            .chain()
                            .focus()
                            .insertContent(text)
                            .extendMarkRange("link")
                            .setLink({ href: url })
                            .run();
                        } else {
                          editor
                            .chain()
                            .focus()
                            .extendMarkRange("link")
                            .unsetLink()
                            .run();
                        }
                        setOpen(false);
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        editor
                          .chain()
                          .focus()
                          .extendMarkRange("link")
                          .unsetLink()
                          .run();
                        setOpen(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      Clear
                    </button>
                  </div>
                  {/* <button
            type="submit"
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Save
          </button> */}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
