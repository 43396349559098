/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import type { User, Post } from "../../../types";
import UserImage from "./userImage";

type PageListProps = {
  posts: Post[];
};

export default function PostList({ posts }: PageListProps) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {posts.map((post) => (
          <li key={`postlist-${post.id}`}>
            <Link to={`/post/${post.id}`} className="block hover:bg-gray-50">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm">
                      <p className="font-medium text-indigo-600 truncate">
                        {post.title}
                      </p>
                      <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                        in {post.type}
                      </p>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          {post.publishedAt ? "Published" : "Created"} on{" "}
                          <time dateTime={post.publishedAt || post.createdAt}>
                            {new Date(
                              post.publishedAt || post.createdAt
                            ).toLocaleDateString()}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex overflow-hidden -space-x-1">
                      {post.authors.map((author) => (
                        <UserImage
                          key={`author-image-${post.id}-${(author as User).id}`}
                          className="h-6 w-6"
                          imageUrl={(author as User).imageUrl}
                          alt={(author as User).name}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
